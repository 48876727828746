import { useState } from "react";
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
// import NextLink from 'next/link';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Search as SearchIcon } from "../../icons/search";

export const SalesListToolbar = (props) => {
  
  const [value, setValue] = useState([
    dayjs('2023-05-25'),
    dayjs('2023-05-25'),
  ]);

  // const [filter, setFilter] = useState(1);

  // const onInputHandler = e => {
  //   props.onInput(e.target.value);
  // }

  // const onSelectHandler = e => {
  //   props.onSelect(e.target.value)
  //   setFilter(e.target.value)
  // }

  // const onInputHandler = () => {
  //   console.log(input);
  //   props.onInput(input);
  //   }

  return (
    <Box {...props}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          m: -1,
        }}
      >
        <Typography sx={{ m: 1 }} variant="h4">
          Ventas
        </Typography>
        <Box sx={{ m: 1 }}>
          <Link to="new">
            <Button color="primary" variant="contained">
              Nueva Venta
            </Button>
          </Link>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateRangePicker', 'DateRangePicker']}>
        <DemoItem label="Uncontrolled picker" component="DateRangePicker">
          <DateRangePicker
            defaultValue={[dayjs('2022-04-17'), dayjs('2022-04-21')]}
          />
        </DemoItem>
        <DemoItem label="Controlled picker" component="DateRangePicker">
          <DateRangePicker
            value={value}
            onChange={(newValue) => setValue(newValue)}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={3} lg={3}>
                <FormControl fullWidth>
                  <InputLabel id="status">Estado</InputLabel>
                  <Select
                    // labelId="status"
                    fullWidth
                    value={props.valSelect}
                    label="Estado"
                    // displayEmpty
                    onChange={props.onSelect}
                    name="filter"
                  >
                    <MenuItem value={0}>Todos</MenuItem>
                    <MenuItem value={2}>Entregado</MenuItem>
                    <MenuItem value={1}>Pendiente</MenuItem>
                    <MenuItem value={3}>Anulado</MenuItem>
                    <MenuItem value={4}>Falta pagar</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3} lg={3}>
                <FormControl fullWidth>
                  <InputLabel id="status">Estado</InputLabel>
                  <Select
                    // labelId="status"
                    fullWidth
                    value={props.valSelect}
                    label="Estado"
                    // displayEmpty
                    onChange={props.onSelect}
                    name="filter"
                  >
                    <MenuItem value={0}>Todos</MenuItem>
                    <MenuItem value={2}>Entregado</MenuItem>
                    <MenuItem value={1}>Pendiente</MenuItem>
                    <MenuItem value={3}>Anulado</MenuItem>
                    <MenuItem value={4}>Falta pagar</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3} lg={3}>
                <FormControl fullWidth>
                  <InputLabel id="status">Estado</InputLabel>
                  <Select
                    // labelId="status"
                    fullWidth
                    value={props.valSelect}
                    label="Estado"
                    // displayEmpty
                    onChange={props.onSelect}
                    name="filter"
                  >
                    <MenuItem value={0}>Todos</MenuItem>
                    <MenuItem value={2}>Entregado</MenuItem>
                    <MenuItem value={1}>Pendiente</MenuItem>
                    <MenuItem value={3}>Anulado</MenuItem>
                    <MenuItem value={4}>Falta pagar</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} lg={12}>
                <TextField
                  type="search"
                  name="search"
                  fullWidth
                  onChange={props.onInput}
                  value={props.valInput}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                    autoComplete: "off",
                  }}
                  placeholder="Buscar cliente por nombre o DNI"
                  variant="outlined"
                />
              </Grid>
              {/* <Grid 
                item 
                xs={12}
                sm={6}
                lg={3}
              >
                
              </Grid> */}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};
