import { TextField } from "@mui/material";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
// import { DatePicker } from "@mui/x-date-pickers";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useField, useFormikContext } from "formik";

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  // const [value, setValue] = React.useState(null);
  return (
    <DemoContainer components={["DatePicker"]}>
        {/* <DatePicker
          {...field}
          {...props}
          value={field.value && new Date(field.value)}
          onChange={(val) => {
            setFieldValue(field.name, val);
          }}
          renderInput={(params) => (
            <TextField {...params} fullWidth sx={{ marginBottom: "20px" }} />
          )}
        /> */}

        <DatePicker
          // {...field}
          {...props}
          value={field.value ? dayjs(new Date(field.value)) : null}
          onChange={(val) => {
            setFieldValue(field.name, val);
          }}
          renderInput={(params) => (
            <TextField {...params} fullWidth sx={{ marginBottom: "20px" }} className="Daniel" />
          )}
        />
    </DemoContainer>
  );
};

export default DatePickerField;
