import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Box, Container } from '@mui/material';
import { InventoryListToolbar } from '../components/inventory/inventory-list-toolbar';
import { InventoryListResults } from '../components/inventory/inventory-list-results';
import ProductService from '../services/product.service';
import InventoryService from '../services/inventory.service';
import { isNumber } from 'lodash';

const Inventory = () => {
  const inventoryService =  new InventoryService();
  const { user } = useSelector(state => state.user);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(1);
  const [data, setData] = useState([]);

//   const getSales = async (p, l, s, f) => {
//     try {
//       saleService.setAccessToken(user.accessToken);
//       const response = await saleService.getSales(p, l, s, f);
//       setData(response.data);
//       setSales(response.data.data);
//     } catch (error) {
//       console.log(error)
//     }
//   };

  const getProducts = async (p, l, s, f) => {
    try {
      inventoryService.setAccessToken(user.accessToken);
      // const response = await productService.getSales(p, l, s, f);
      let response = '';
      if (s) {
        // alert('datos');
        response = await inventoryService.getInventoryPerSearch(s);
      } else {
        // alert('sin datos');
        response = await inventoryService.getInventory();
      }
      setData(response.data);
      setProducts(response.data.data);
    } catch (error) {
      console.log(error)
    }
  };

  const changePage = (page) => {
    setPage(page);
  }
  const changeLimit = (limit) => {
    setLimit(limit);
  }


  const selectHandler = (e) => {
    if (e.target.value !== '' && isNumber(e.target.value)) {
      setFilter(e.target.value);
    }
  }

  const handleInput = (event) => {
    setSearch(event.target.value);
  }

  useEffect(()=>{
    // (async function getRecords() {
        getProducts(page, limit, search, filter);
    // })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, search, filter])

  return(
    <>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8
      }}
    >
      <Container maxWidth={false}>
        <InventoryListToolbar
          onInput={handleInput}
          //valInput={search}
          // onInput={inputHandler}
         // valSelect={filter}
         // onSelect={selectHandler}
        />
        <Box sx={{ mt: 3 }}>
          <InventoryListResults
            products={products}
            changePage={changePage}
            changeLimit={changeLimit}
            count={data.records_total || 0}
          />
        </Box>
      </Container>
    </Box>
  </>
  )
};


export default Inventory;
