import { serialize } from 'object-to-formdata';

import AppService from './app.service';

class InventoryService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('inventory');
  }

  getInventory() {
    return this.http.get(`${this.path}`);
  }

  getInventoryForSelect() {
    return this.http.get('/inventory/select');
  }

  getInventoryPerPage(pageNumber) {
    return this.http.get('/inventory?page=' + pageNumber);
  }
  getInventoryPerSearch(value) {
    return this.http.get('/inventory/search?search=' + value);
  }

  newInventory(data) {
    return this.http.post('/inventory/new', data);
  }

  updateInventory(data, id) {
    return this.http.patch(`/inventory/update/${id}`, data);
  }
//   create(data) {
//     const formData = serialize(data);
//     return this.http.post(`${this.path}`, formData);
//   }
//   read(id) {
//     return this.http.get(`${this.path}/${id}`);
//   }
//   update(data, id) {
//     const formData = serialize({
//       ...data,
//       _method: 'PATCH',
//     });
//     return this.http.post(`${this.path}/${id}`, formData);
//   }
//   uploadLogo(value, id) {
//     const formData = serialize({
//       logo: value,
//     });
//     return this.http.post(`${this.path}/${id}`, formData);
//   }
//   getLogo(id) {
//     return this.http.get(`${this.path}/${id}/logo`);
//   }
  list(pageSize, page) {
    return this.http.get(`${this.path}?render=paginate&size=${pageSize}&page=${page}`);
  }
//   filterStatus(status, pageSize, page) {
//     return this.http.get(
//       `${this.path}?filter[status]=${status}&render=paginate&size=${pageSize}&page=${page}`
//     );
//   }
//   search(query, pageSize = 20, page = 1) {
//     return this.http.get(
//       `${this.path}?render=paginate&size=${pageSize}&page=${page}&filter[name]=${query}`
//     );
//   }
//   listSearch(search) {
//     return this.http.get(`${this.path}?${search}`);
//   }
}

export default InventoryService;
