import { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { SeverityPill } from '../severity-pill';
import { format, parseISO } from 'date-fns';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  Drawer
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { upperCase } from 'lodash';


export const InventoryListResults = ({ products, changePage, changeLimit, count, ...rest }) => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (val) => {
    setAnchorEl(!anchorEl);
    console.log(val);
  };
  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    changeLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    changePage(newPage + 1);
  };

  const handleChange = (id, status) => {
    console.log(status);
    console.log(id);
  }

  const options = (opt) => {
    switch (opt) {
      case 2: return 'Entregado';
      case 3: return 'Anulado';
      case 4: return 'Con Deuda';
      default: return 'Pendiente';
    };
  }

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell >
                    Id
                  </TableCell>
                  <TableCell>
                    Nombre
                  </TableCell>
                  <TableCell>
                    Color
                  </TableCell>
                  <TableCell>
                    Talla
                  </TableCell>
                  <TableCell>
                    Precio
                  </TableCell>
                  <TableCell>
                    Cantidad
                  </TableCell>
                  <TableCell>
                    Stock
                  </TableCell>
                  <TableCell>
                    Acciones
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products?.map((product) => (
                  <TableRow
                    hover
                    key={product.id}
                  >
                    <TableCell padding="checkbox">
                      {product.id} 
                    </TableCell>
                    <TableCell>
                      {product.category_name} {' '} {upperCase(product.product_name)}
                    </TableCell>
                    <TableCell>
                      {product.color_name}
                    </TableCell>
                    <TableCell>
                      {product.size_name}
                    </TableCell>
                    <TableCell>
                      {product.quantity}
                    </TableCell>
                    <TableCell>
                      {product.stock}
                    </TableCell>
                    <TableCell>
                      S/ {`${product.price}.00`}
                    </TableCell>
                    <TableCell>
                      <Link to={`/admin/document/${product.id}`} key={`print-${product.id}`}>Ver </Link>
                      {
                        product.status !==3 &&
                        <Link to={`/admin/sales/${product.id}/edit`} key={`edit-${product.id}`} >edit </Link>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody> 
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 50]}
        />
      </Card>
      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            color: '#FFFFFF',
            width: 500
          }
        }}
        open={anchorEl}
        anchor='right'
        onClose={handleClose}
        sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
        variant='temporary'
      >
        <h1>HOLA</h1>
      </Drawer>
    </>
  );
};

InventoryListResults.propTypes = {
  sales: PropTypes.array.isRequired
};
