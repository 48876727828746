import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { AppBar, Avatar, Badge, Box, IconButton, Toolbar, Tooltip, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
// import SearchIcon from '@mui/icons-material/Search';
// import { Bell as BellIcon } from '../icons/bell';
// import { UserCircle as UserCircleIcon } from '../icons/user-circle';
// import { Users as UsersIcon } from '../icons/users';
import { useSelector, useDispatch } from 'react-redux';
import { currentStore } from '../app/features/user/userSlice'

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3]
}));

export const DashboardNavbar = (props) => {
  const { onSidebarOpen, ...other } = props;
  const dispatch = useDispatch();
  const { store } = useSelector(state => state.user);
  const [storeId,setStoreId] = useState(store);



  const handleChange = (event) => {
    setStoreId(event.target.value);
    dispatch(currentStore(event.target.value));
  };
  return (
    <>
      <DashboardNavbarRoot
        sx={{
          left: {
            lg: 280
          },
          width: {
            lg: 'calc(100% - 280px)'
          }
        }}
        {...other}>
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2
          }}
        >
          <IconButton
            onClick={onSidebarOpen}
            sx={{
              display: {
                xs: 'inline-flex',
                lg: 'none'
              }
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          {/* <Tooltip title="Search">
            <IconButton sx={{ ml: 1 }}>
              <SearchIcon fontSize="small" />
            </IconButton>
          </Tooltip> */}
          <Box sx={{ flexGrow: 1 }} />
          {/* <Tooltip title="Contacts">
            <IconButton sx={{ ml: 1 }}>
              <UsersIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Notifications">
            <IconButton sx={{ ml: 1 }}>
              <Badge
                badgeContent={4}
                color="primary"
                variant="dot"
              >
                <BellIcon fontSize="small" />
              </Badge>
            </IconButton>
          </Tooltip> */}
          <FormControl>
            {/* <InputLabel id="store-select-label">Tienda</InputLabel> */}
            <Select
              labelId="store-select-label"
              id="store-select"
              value={storeId}
              label="Tienda"
              sx={{width: 240, ml: 1}}
              onChange={handleChange}
            >
              <MenuItem value={1}>Alicia Luque</MenuItem>
              <MenuItem value={2}>Malicia Moda</MenuItem>
              {/* <MenuItem value={2}>Tienda Jesus Maria</MenuItem>
              <MenuItem value={3}>Tienda Surco</MenuItem> */}
            </Select>
          </FormControl>
          <Avatar
            sx={{
              height: 40,
              width: 40,
              ml: 1
            }}
            src="/static/images/avatars/avatar_1.png"
          >
            {/* <UserCircleIcon fontSize="small" /> */}
          </Avatar>
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
};

DashboardNavbar.propTypes = {
  onSidebarOpen: PropTypes.func
};
