import { serialize } from 'object-to-formdata';

import AppService from './app.service';

class CustomerService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('customers');
  }
//   create(data) {
//     const formData = serialize(data);
//     return this.http.post(`${this.path}`, formData);
//   }
//   read(id) {
//     return this.http.get(`${this.path}/${id}`);
//   }
//   update(data, id) {
//     const formData = serialize({
//       ...data,
//       _method: 'PATCH',
//     });
//     return this.http.post(`${this.path}/${id}`, formData);
//   }
//   uploadLogo(value, id) {
//     const formData = serialize({
//       logo: value,
//     });
//     return this.http.post(`${this.path}/${id}`, formData);
//   }
//   getLogo(id) {
//     return this.http.get(`${this.path}/${id}/logo`);
//   }
  list(pageSize, page) {
    return this.http.get(`${this.path}?render=paginate&size=${pageSize}&page=${page}`);
  }

  search(value) {
    return this.http.get(`${this.path}/search?search=${value}`);
  }

  searchDni(value) {
    return this.http.get(`customer/dni?search=${value}`);
  }

  searchRuc(value) {
    return this.http.get(`customer/ruc?search=${value}`);
  }

  searchDocument(data) {
    const formData = serialize(data);
    return this.http.post(`/sale/dni`, formData);
  }
//   filterStatus(status, pageSize, page) {
//     return this.http.get(
//       `${this.path}?filter[status]=${status}&render=paginate&size=${pageSize}&page=${page}`
//     );
//   }
//   search(query, pageSize = 20, page = 1) {
//     return this.http.get(
//       `${this.path}?render=paginate&size=${pageSize}&page=${page}&filter[name]=${query}`
//     );
//   }
//   listSearch(search) {
//     return this.http.get(`${this.path}?${search}`);
//   }
  me() {
    return this.http.get(`/users`);
  }
}

export default CustomerService;
