import { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { SeverityPill } from '../severity-pill';
import { format, parseISO } from 'date-fns';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  Drawer
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { Link } from 'react-router-dom';


export const SaleListResults = ({ sales, changePage, changeLimit, count, ...rest }) => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (val) => {
    setAnchorEl(!anchorEl);
    console.log(val);
  };
  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    changeLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    changePage(newPage + 1);
  };

  const handleChange = (id, status) => {
    console.log(status);
    console.log(id);
  }

  const options = (opt) => {
    switch (opt) {
      case 2: return 'Entregado';
      case 3: return 'Anulado';
      case 4: return 'Con Deuda';
      default: return 'Pendiente';
    };
  }

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell >
                    Id
                  </TableCell>
                  <TableCell>
                    Fecha de Compra
                  </TableCell>
                  <TableCell>
                    DNI
                  </TableCell>
                  <TableCell>
                    Cliente
                  </TableCell>
                  <TableCell>
                    Monto
                  </TableCell>
                  <TableCell>
                    Enviar por
                  </TableCell>
                  <TableCell>
                    Fecha de envio
                  </TableCell>
                  <TableCell>
                    Tienda
                  </TableCell>
                  <TableCell>
                    Estado
                  </TableCell>
                  <TableCell>
                    Acciones
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sales?.map((sale) => (
                  <TableRow
                    hover
                    key={sale.id}
                  >
                    <TableCell padding="checkbox">
                      {sale.id}
                    </TableCell>
                    <TableCell>
                      {format(parseISO(sale.sale_at), 'dd/MM/yyyy')}
                    </TableCell>
                    <TableCell>
                      {sale.dni}
                    </TableCell>
                    <TableCell>
                      {sale.full_name}
                    </TableCell>
                    <TableCell>
                      S/ {`${sale.price}.00`}
                    </TableCell>
                    <TableCell>
                      {sale.shipping_type}
                    </TableCell>
                    <TableCell>
                      {format(parseISO(sale.send_at), 'dd/MM/yyyy')}
                    </TableCell>
                    <TableCell>
                      {sale.store_id}
                    </TableCell>
                    <TableCell>
                      <SeverityPill
                        color={(sale.status === 2 && 'success')
                        || (sale.status === 3 && 'error')
                        || (sale.status === 4 && 'info')
                        || 'warning'}
                      >
                        {options(sale.status)}
                      </SeverityPill>
                    </TableCell>
                    <TableCell>
                      <Link to={`/admin/document/${sale.id}`} key={`print-${sale.id}`}>Ver </Link>
                      {
                        sale.status !==3 &&
                        <Link to={`/admin/sales/${sale.id}/edit`} key={`edit-${sale.id}`} >edit </Link>
                      }
                      {/* <IconButton
                        edge="end"
                        size="small"
                        onClick={() =>handleClick(sale)}
                      >
                        <MoreVert />
                      </IconButton> */}
                      {/* <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuItem onClick={handleClose}>Ver</MenuItem>
                        <MenuItem onClick={handleClose}>Editar</MenuItem>
                        <MenuItem onClick={() => handleChange(sale.id, 2)}>Entregado</MenuItem>
                        <MenuItem onClick={() => handleChange(sale.id, 1)}>Pendiente</MenuItem>
                        <MenuItem onClick={() => handleChange(sale.id, 3)}>Anular</MenuItem>
                        <MenuItem onClick={() => handleChange(sale.id, 4)}>Falta pagar</MenuItem>
                        <MenuItem onClick={() => handleChange(sale.id, 5)}>Eliminar</MenuItem>
                      </Menu> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 50]}
        />
      </Card>
      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            color: '#FFFFFF',
            width: 500
          }
        }}
        open={anchorEl}
        anchor='right'
        onClose={handleClose}
        sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
        variant='temporary'
      >
        <h1>HOLA</h1>
      </Drawer>
    </>
  );
};

SaleListResults.propTypes = {
  sales: PropTypes.array.isRequired
};
