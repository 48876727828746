import axios from 'axios';

import {
  get as _get, has as _has, isEmpty as _isEmpty, isNull as _isNull,
} from 'lodash';

import store from '../app/store';

class AppService {
  constructor() {
    this.http = axios.create({
      // baseURL: 'http://api-local.malicia.com:8000/api/v1',
    baseURL: 'https://api.malicia-core.com/api/v1',
    });
    this.http.defaults.params = {};
    this.initInterceptors();
  }

  initInterceptors() {
    this.http.interceptors.request.use(
      (request) => {
        const accessToken = this.getAccessToken();
        if (!_isNull(accessToken)) {
          request.headers.common['Authorization'] = 'Bearer ' + accessToken;
        }
        return request;
      },
      (error) => Promise.reject(error)
    );

    this.http.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response.status === 401 &&
          _has(error.response, 'config.url') &&
          _get(error.response, 'config.url') !== undefined &&
          _get(error.response, 'config.url') !== '/sign-in'
        ) {
          window.location = '/login';
          console.log(error.response);
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  init(accessToken = '') {
    this.setAccessToken(accessToken);
  }

  setAccessToken(accessToken) {
    // console.log(accessToken);
    if (!_isEmpty(accessToken)) {
      this.http.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
    } else {
      delete this.http.defaults.headers.common['Authorization'];
    }
  }

  getAccessToken() {
    const state = store.getState();
    // console.log(state);
    return state?.user?.accessToken ?? null;
  }

  reset() {
    delete this.http.defaults.headers.common['Authorization'];
    this.http.defaults.params = {};
  }
  getOptions() {
    return this.http.defaults.headers;
  }
  getPath(service) {
    return '/' + service;
  }
  logout() {
    return this.http.post('/auth/logout');
  }
}

export default AppService;
