import { serialize } from 'object-to-formdata';

import AppService from './app.service';

class ProductService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('products');
  }

  getProducts() {
    return this.http.get(`${this.path}`);
  }

  getProductsPerPage(pageNumber) {
    return this.http.get('/products?page=' + pageNumber);
  }

  getProductsPerSearch(value) {
    return this.http.get('/products/search?search=' + value);
  }

  getProductSelect() {
    return this.http.get(`inventory/select`);
  }

  getSelect(search, page, store = 1) {
    return this.http.get(`inventory/get-select?search=${search}&store=${store}&page=${page}`);
  }
//   create(data) {
//     const formData = serialize(data);
//     return this.http.post(`${this.path}`, formData);
//   }
//   read(id) {
//     return this.http.get(`${this.path}/${id}`);
//   }
//   update(data, id) {
//     const formData = serialize({
//       ...data,
//       _method: 'PATCH',
//     });
//     return this.http.post(`${this.path}/${id}`, formData);
//   }
//   uploadLogo(value, id) {
//     const formData = serialize({
//       logo: value,
//     });
//     return this.http.post(`${this.path}/${id}`, formData);
//   }
//   getLogo(id) {
//     return this.http.get(`${this.path}/${id}/logo`);
//   }
  list(pageSize, page) {
    return this.http.get(`${this.path}?render=paginate&size=${pageSize}&page=${page}`);
  }
//   filterStatus(status, pageSize, page) {
//     return this.http.get(
//       `${this.path}?filter[status]=${status}&render=paginate&size=${pageSize}&page=${page}`
//     );
//   }
//   search(query, pageSize = 20, page = 1) {
//     return this.http.get(
//       `${this.path}?render=paginate&size=${pageSize}&page=${page}&filter[name]=${query}`
//     );
//   }
//   listSearch(search) {
//     return this.http.get(`${this.path}?${search}`);
//   }
}

export default ProductService;
