import SelectField from "../select.widget";

const shipping = [
  {
    value: 1,
    key: 'Malicia'
  },
  {
    value: 2,
    key: 'Olva Courier'
  },
  {
    value: 3,
    key: 'Agencia'
  },
  {
    value: 4,
    key: 'Tienda'
  },
  {
    value: 5,
    key: 'Tienda Jesus maria'
  },
  {
    value: 6,
    key: 'Tienda Surco'
  },
  {
    value:7,
    key: 'Motorizado'
  },
  {
    value: 8,
    key: 'Almacen'
  },
]

export const ShippingSelect = ({...props}) => {
    return (
      <SelectField
        data={shipping}
        {...props}
      />
    );
}