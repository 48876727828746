import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Box, Container } from '@mui/material';
import { SaleListResults } from '../components/sale/sale-list-results';
import { SalesListToolbar } from '../components/sale/sale-list-toolbar';
import SaleService from '../services/sale.service';
import { isNumber } from 'lodash';

const Sales = () => {
  const saleService =  new SaleService();
  const { user } = useSelector(state => state.user);
  const [sales, setSales] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(1);
  const [data, setData] = useState([]);

  const getSales = async (p, l, s, f) => {
    try {
      saleService.setAccessToken(user.accessToken);
      const response = await saleService.getSales(p, l, s, f);
      setData(response.data);
      setSales(response.data.data);
    } catch (error) {
      console.log(error)
    }
  };

  const changePage = (page) => {
    setPage(page);
  }
  const changeLimit = (limit) => {
    setLimit(limit);
  }


  const selectHandler = (e) => {
    if (e.target.value !== '' && isNumber(e.target.value)) {
      setFilter(e.target.value);
    }
  }

  const handleInput = (event) => {
    setSearch(event.target.value);
  }

  useEffect(()=>{
    console.log(filter);
    // (async function getRecords() {
      getSales(page, limit, search, filter);
    // })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, search, filter])

  return(
    <>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8
      }}
    >
      <Container maxWidth={false}>
        <SalesListToolbar
          onInput={handleInput}
          valInput={search}
          // onInput={inputHandler}
          valSelect={filter}
          onSelect={selectHandler}
        />
        <Box sx={{ mt: 3 }}>
          <SaleListResults
            sales={sales}
            changePage={changePage}
            changeLimit={changeLimit}
            count={data.records_total || 0}
          />
        </Box>
      </Container>
    </Box>
  </>
  )
};


export default Sales;
